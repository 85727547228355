import { render, staticRenderFns } from "./TenderPage.vue?vue&type=template&id=e36b1000&scoped=true"
import script from "./TenderPage.vue?vue&type=script&lang=js"
export * from "./TenderPage.vue?vue&type=script&lang=js"
import style0 from "./TenderPage.vue?vue&type=style&index=0&id=e36b1000&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e36b1000",
  null
  
)

export default component.exports